@import "../../const/settings.scss";
.dataHeader {
  position: sticky;
  top: 0;
  z-index: 10;
}
.wrapperHeader {
  background: #fff;
  position: sticky;
  top: 0;
  .contentHeader {
    max-width: 1200px;
    margin: auto;
    align-items: center;
    display: flex;
    height: 90px;
    .wrapperTop {
      display: flex;
      align-items: center;
    }
    .wrapperRight {
      // display: flex;
      display: none;

      align-items: center;
    }
  }
  .wpLogo {
    cursor: pointer;
    .logo {
      height: 36px;
      object-fit: contain;
      width: fit-content;
    }
    .logoMobile {
      display: none;
    }
  }
  .menuHeader {
    display: none;
  }
  .dropdownHeader {
    display: flex;
    height: 48px;
    width: auto;
    // max-width: 730px;
    color: #2a3467;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin: auto;
    padding: 12px 0;
    .dropdownLink {
      color: #2a3467 !important;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      // &:nth-child(1) {
      //   margin-right: 32px;
      // }
      // &:nth-child(2) {
      //   margin-right: 32px;
      // }
      // &:nth-child(3) {
      //   margin-right: 32px;
      // }
      // &:nth-child(4) {
      //   margin-right: 32px;
      // }
      // .itemHover {
      //   cursor: default;
      // }
    }
  }
  .buttonClinic,
  .buttonClinicRg {
    // padding: 12px 24px;
    width: 100px;
    background: #ffffff;
    color: #2a3467 !important;
    border: 1px solid #00c5d7 !important;
    border-radius: 5px;
    height: 35px;
    padding: 0px 10px !important;
    font-weight: 600;
    font-size: 14px;
    // line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatar {
    margin-left: 10px;
    cursor: pointer;
    @include media(md) {
      margin-right: 10px;
    }
  }
}
.dropUser {
  font-size: 24px;
  color: #00c5d7 !important;
  padding-left: 20px;
}
.labelSignOut {
  border: none;
  background: none;
}
.wpAct {
  display: flex;
  .btnAdvice {
    background: rgba(187, 3, 3, 1) !important;
    border-radius: 5px;
    height: 35px;
    color: var(--Color-3, #fff) !important;
    font-size: 14px;
    font-style: normal;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    border: none !important;
  }
  .btnCall {
    margin: 0 8px;
    border-radius: 5px;
    height: 35px;
    color: var(--Color-3, #fff) !important;
    font-size: 14px;
    font-style: normal;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    border: 1px solid #00c5d7 !important;
    background: var(--Color-6, #29c0d3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: unset;
    img {
      margin-right: 5px;
      width: 25px;
      height: 25px;
    }
    @include media(lg) {
      margin: 0px;
    }
  }
}

.wpSignInMobile {
  display: grid;
  justify-content: center;
  display: none;
}
@include media(lg) {
  .wrapperHeader {
    z-index: 9999;
    position: relative;
    .contentHeader {
      height: 68px;
      justify-content: space-between;
      padding: 0 15px;
    }
    .wpLogo {
      cursor: pointer;
      .logo {
        display: none;
      }
      .logoMobile {
        display: block !important;
        object-fit: contain;
        width: fit-content;
        margin: 6px 19px 6px 4px;
        width: 140px;
        height: 28px;
      }
    }
    .menuHeader {
      color: #6884a7;
      // margin-left: 26px;
      margin-right: 15px;
      height: 100%;
      align-items: center;
      display: flex;
    }
    .dropdownHeader {
      display: none !important;
    }

    .buttonClinic,
    .buttonClinicRg {
      display: none !important;
      // width: 134px;
      // height: 32px;
      // padding: 7px 8px;
      // font-size: 12px;
      // line-height: 18px;
      // margin-left: auto;
      // margin-right: 15px;
    }
    .dropUser {
      display: none !important;
    }
    .wpAct {
      display: none !important;
    }
  }
}

@import "../../const/settings.scss";
.dropdownHeaderMobile {
  .dropdownHeaderMobileItem {
    padding: 14px 20px;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    color: #2a3467 !important;
    span {
      cursor: pointer;
      &:hover {
        color: #40a9ff;
      }
    }
    &:nth-child(1) {
      margin-top: 20px;
    }
  }
  .dropdownHeaderMobileItem.opened {
    background: #f9f9f9;
  }
  .dropdownLink {
    display: flex;
    justify-content: space-between;
  }

  .loginButton {
    width: 252px;
    height: 36px;
    border-radius: 7px;
    border: 1px solid rgba(0, 197, 215, 1);
    background: #ffffff;
    color: rgba(0, 197, 215, 1);
    font-size: 18px;
  }
  .registerButton {
    width: 252px;
    height: 36px;
    border-radius: 7px;
    border: 1px solid rgba(0, 197, 215, 1);
    background: rgba(0, 197, 215, 1);
    color: #ffffff;
    font-size: 18px;
  }

  .menu-clinic,
  .menu-blog {
    max-height: 100px;
    overflow: hidden;

    .ant-menu-title-content {
      color: #2a3467;

      a > &:hover {
        color: #40a9ff;
      }
    }
  }
}
.menu-clinic,
.menu-blog {
  .ant-dropdown-menu {
    max-height: 80px;
    overflow: hidden;
  }
}
// Custom Drawer
.ant-drawer-content-wrapper {
  // margin-top: 68px;
  width: calc(100% - 49px) !important;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

// Custom Menu
.ant-menu-inline {
  max-height: 0px;
  overflow: auto;
  overflow-x: hidden;
  background: #f9f9f9;
  font-size: 14px;
  line-height: 21px;
  transition: max-height 0.2s ease-out;
  a {
    font-size: 14px;
    color: #2a3467;
    :hover {
      color: #2a3467;
    }
  }
}

.dropdownHeaderMobileItem.opened + .ant-menu-inline {
  max-height: 219px;
}

.ant-dropdown-menu {
  margin-top: 5px;
  border-radius: 8px;
  .ant-dropdown-menu-title-content {
    color: #2a3467;
    font-size: 16px !important;
  }
  .hiddenBlock {
    display: none;
  }
}
.ant-drawer-body {
  .hiddenBlock {
    display: none;
  }
}
.drop-content {
  width: auto;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 400px;
  margin-top: 5px;
  padding: 6px 0;
  a,
  span {
    color: #2a3467;
    font-size: 16px !important;
    padding: 0 12px;
    display: block;
    cursor: pointer;
  }
}

#user-dropdown {
  // .ant-dropdown-menu {
  //   margin-top: 24px;
  // }
  .labelSignOut {
    border: none;
    background: none;
    color: inherit !important;
  }
  .avatar-image {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    position: relative !important;
  }
}
.modalUser {
  .ant-modal-header {
    .ant-modal-title {
      font-size: 18px;
      color: #2a3467;
    }
    border-radius: 16px 16px 0 0;
    background-color: #f9f9f9 !important;
    border-bottom: 0;
  }
  .ant-modal-content {
    border-radius: 16px !important;
    background-color: #f9f9f9 !important;
  }
  .avatar-user {
    .ant-upload-select {
      border-radius: 50% !important;
      .anticon-plus {
        color: black !important;
      }
      &:hover {
        border-color: #00c5d7 !important;
      }
    }
    .ant-upload-list {
      display: flex;
      justify-content: center !important;
    }
    .ant-form-item-row {
      flex-direction: column-reverse !important;
      gap: 10px;
      align-items: center !important;
    }
    .ant-upload-list-item-container,
    .ant-upload-list-item-thumbnail,
    .ant-upload-list-item-done,
    .ant-upload-list-item-done::before {
      border-radius: 50% !important;
    }
  }
  input {
    border: none;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;
    color: #2a3467;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    &::placeholder {
      color: #6884a7;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .wrapper-button-submit {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    .button-submit {
      height: 48px;
      border-radius: 8px;
      background-color: rgb(0, 197, 215);
      font-size: 18px;
      padding: 0 16px;
    }
    .ant-btn-primary {
      border-color: transparent !important;
    }
  }
  .ant-upload-select-picture-card {
    border-radius: 50%;
    background: #fff;
  }
  .ant-form-item-label label {
    font-size: 16px !important;
    color: #2a3467 !important;
  }
  .ant-upload-list-item-list-type-picture-card,
  .ant-upload-list-item-info {
    border-radius: 50%;
  }
}

.img-crop-modal {
  .ant-modal-header {
    .ant-modal-title {
      font-size: 18px;
      color: #2a3467;
    }
    border-radius: 16px 16px 0 0;
    background-color: #f9f9f9;
    border-bottom: 0;
  }
  .ant-modal-content {
    border-radius: 16px !important;
    background-color: #f9f9f9;
  }
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      height: 38px;
      border-radius: 8px;
      background: #00c5d7;
      border-color: transparent;
      a {
        font-size: 16px;
        &:hover {
          color: #fff !important;
        }
      }
    }
    .ant-btn.ant-btn-default {
      &:hover {
        border-color: #00c5d7;
      }
      height: 38px !important;
      border-radius: 8px;
      border-color: #00c5d7;
      span {
        font-size: 16px;
        color: #00c5d7;
      }
    }
  }
}
@include media(md) {
  .image-user {
    width: 35px !important;
    height: 35px !important;
    line-height: 32px !important;
    font-size: 16px !important;
  }
  #user-dropdown {
    .avatar-image {
      width: 35px !important;
      height: 35px !important;
    }
  }
}
@include media(lg) {
  .wpActHidenDesktop {
    display: none !important;
  }
}

@import "../../const/settings.scss";
.wrapperFooter {
  // height: 537px;
  width: 100%;
  background-image: url("../../../public/image/banner_backgr.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 65px 0px;
  overflow: hidden;
  .imgDecorAbove,
  .imgDecorBelow {
    display: none;
  }
  // .footerFirst {
  //   display: block;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   right: 0;
  //   object-fit: cover;
  // }
  // .footerSecond {
  //   display: none;
  //   object-fit: cover;
  // }
  .contentFooter {
    display: flex;
    margin: 0 0;
    width: 100%;
    max-width: 1200px;
    color: #ffffff;
    .wpLogo {
      .logoFirst {
        display: block;
        margin-bottom: 24px;
      }
      .logoSecond {
        display: none;
      }
    }
    .about {
      padding-right: 32px;
    }
    .socialLink {
      display: flex;
      margin-bottom: 24px;
      button {
        cursor: pointer;
        display: flex;
        background-color: #fff;
        border: none;
        border-radius: 40px;
        padding: 12px;
        align-items: center;
        .iconFooter {
          width: 16px;
          height: 16px;
        }
      }
      button + button {
        margin-left: 12px;
      }
    }
    // .certificateImage{
    //   height: 65px;
    //   width: 172px;
    // }
    .footerHead {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .footerIntroduce {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
      p {
        margin-bottom: 0;
      }
    }
    .footerAdd {
      font-size: 16px;
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }

    .wpFooterLink {
      .footerLink {
        margin-bottom: 8px;
        a {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .hiddenBlock {
        display: none;
      }
    }
  }
}
@include media(lg) {
  .wrapperFooter {
    padding: 65px 15px;
  }
}
@include media(md) {
  .wrapperFooter {
    min-height: auto;
    height: 100%;
    background: linear-gradient(195deg, #2a3467 -0.19%, #00c5d7 125.83%);
    padding: 0 16px;
    .imgDecorAbove {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: auto;
    }
    .imgDecorBelow {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
    }
    // .footerFirst {
    //   display: none;
    // }
    // .footerSecond {
    //   display: block;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   right: 0;
    // }
    .contentFooter {
      margin: 36px 0 73px 0;
      .wpLogo {
        .logoFirst {
          display: none;
        }
        .logoSecond {
          width: 141px;
          height: 83px;
          display: block;
          margin-bottom: 38px;
        }
      }
      .about {
        margin-bottom: 45px;
        .footerIntroduce,
        .footerAdd {
          a,
          p {
            font-size: 12px !important;
          }
        }
      }
      .socialLink {
        margin-bottom: 11px;
        button {
          .iconFooter {
            width: 10px;
            height: 10px;
          }
        }
      }
      .footerHead {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 13px;
      }
      .wpFooterLink {
        margin-bottom: 45px;
        a,
        p {
          font-size: 12px !important;
        }
        .footerLink {
          margin-bottom: 0;
        }
      }
      // .certificateImage{
      //   height: 37px;
      //   width: 102px;
      // }
    }
  }
}

@import "../../const/settings.scss";

.wp-contact-form{
   display: flex;
   justify-content: center;
   .ant-modal-content {
      background-image: url('../../../public/image/dialog-contact.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 25px;
      position: relative;
      .ant-modal-header{
         background: unset;
      }
   
      .ant-modal-body{
         padding: 20px 44px;
         width: 710px;
         .wp-content{
            text-align: center;
            .logo-nhakhoahub{
               width: 218px;
               height: auto;
               margin-bottom: 10px;
            }
            .wp-description{
               .wp-des{
                  text-align: center;
                  font-size: 16px;
                  font-style: normal;
                  line-height: 30px; /* 187.5% */
                  max-width: 468px;
                  margin: 0 auto;
                  span{
                     font-weight: 600;
                  }
               }
               .wp-title{
                  color: var(--Color-5, #293568);
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 30px;
                  padding: 20px 0;
               }
            }
            .warning{
               text-align: left;
               color: #000;
               margin-top: 16px;
               font-size: 14px;
               font-style: italic;
               font-weight: 400;
               line-height: normal;
            }
         }
         .ant-form-item {
            .ant-form-item-label {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: #D41515;
            }
         }
      }

      .ant-modal-footer{
         display: flex;
         justify-content: center;
         border-top: 0px;
         padding-bottom: 30px;
         .ant-btn-default{
            display: none;
         }

         .ant-btn-primary {
            background-color: #D41515;
            border-color: #D41515; 
            border-radius: 30px;
            height: 38px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            span{
               color: var(--Color-3, #FFF);
               font-size: 20px;
               font-style: normal;
               line-height: 30px; 
            }
         }
      }
   }
   .ant-input{
      height: 40px;
   }
}

@include media(md){
   .wp-contact-form {
      .ant-modal-content {
         bottom: 20px;
         .ant-modal-body {
            width: auto;
            padding: 15px 23px;
            .wp-content {
               .logo-nhakhoahub{
                  width: 145px;
                  height: auto;
               }
               .wp-description{
                  .wp-title {
                     font-size: 14px;
                     padding: 13px 0;
                  }
                  .wp-des{
                     font-size: 12px;
                  }
               }
               .warning {
                  font-size: 12px;
               }
            }
            .ant-form-item{
               margin-bottom: 17px;
               .ant-form-item-label {
                  > label {
                     font-size: 12px;
                  }
               }
            }
         }
         .ant-modal-footer .ant-btn-primary{
            width: 106px;
            span{
               font-size: 12px;
               line-height: 0px;
            }
         }
      }
      .ant-input{
         height: auto;
      }
   }
}
